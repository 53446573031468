.centered {
    position: fixed;
    top: 40%;
    left: 48%;
    width: 75px;
    height: 75px;
    z-index: 1150;
}

.bachground-block {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.7;
}