.modalScrollable {
    overflow-y: auto;
    max-height: 800px;

    @media (max-width: 500px) {
        top: 45%;
        width: 89%;
    }
}

.input-custom {
    width: 50% !important;
}