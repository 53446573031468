.logo {
    padding: 0% 0% 0% 6%;
}

.logo img {
    height: 50px;
}

.home-container {
    width: 100%;
    height: 220px;
    padding-top: 5% !important;

}

.bodyLogin {
    background-image: url('../../assets/login-background.jpg');
    background-size: cover;
    height: 100vh !important;
}

.outer-page {
    height: auto;
    padding: 3% 2% 3% 2%;
    border: 1px solid #ddd;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    background: #f5f6fb;
    font-weight: 400;
    color: #3b3b3b;
    box-shadow: 0 0 2px #ccc;
    font-size: 13px !important;
}

.l-middle-container {
    background: white;
    border-radius: 10px;
    padding: 2% 4% 2% 4%;
    border: 1px solid #ddd;
}

.login-option-btn-container {
    padding: 20px;
}

.login-option-btn {
    width: 260px;
    height: 42px;
}

.login-option-link {
    width: 260px;
    height: 42px;

}

.linkText {
    color: white;
    text-decoration: none;
}