.tableContainerHome {
    height: calc(87vh - 71px);
}

.css-3ihp42-MuiDataGrid-root .MuiDataGrid-cellContent {
    font-size: 12px;

    @media(max-width: 1400px) {
        font-size: 14px;
    }
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-size: 12px;
    font-weight: 600 !important;

    @media(max-width: 1400px) {
        font-size: 14px;
    }
}

.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar {
    width: 7px;
    border-radius: 8px;
}

.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    box-shadow: none;
}

.css-1w5m2wr-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;

}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    padding: 6px 24px !important;
    line-height: 1.6 !important;
    font-size: 18px !important;
}

.css-hlj6pa-MuiDialogActions-root {
    padding: 2px;
}

.table-header {
    justify-content: space-between;
    display: flex;
    margin-bottom: 2px;
}

.css-yz9k0d-MuiInputBase-input {
    font-size: 14px !important;
}

.modal-body-width50 .MuiPaper-root {
    width: 40%;
}

.paperStyleHome {
    height: 34px;
    font-size: 12px;
    width: auto !important;
}

.homePageContainer {
    padding-top: 0px !important;
}