.sidebar{
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 100%;
    max-height: 100%;
    -webkit-transition-property: top,bottom,width;
    transition-property: top,bottom,width;
    -webkit-transition-duration: .2s,.2s,.35s;
    transition-duration: .2s,.2s,.35s;
    -webkit-transition-timing-function: linear,linear,ease;
    transition-timing-function: linear,linear,ease;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 220px;
    box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    &::before{
        background-color: #005cb9;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        z-index: -1;
    }

    .logo{
        margin: 10px 15px;
        padding-bottom: 10px;
        color: #fff;
        text-transform: uppercase;
        border-bottom: 1px solid #fff;
        img{
            width: 176px;
            height: 44px;
            text-align: center;
            &.small-logo{
                display: none;
            }
        }
    }
    .sidebar-wrapper{
        padding: 0px 15px;
        overflow-y: scroll;
        height: calc(100% - 20px);
        &::-webkit-scrollbar {
            width: 7px;
            border-radius: 8px;
          }
           
        &::-webkit-scrollbar-track {
            box-shadow: none;
        }
           
        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 8px;
        }
        .user{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #fff;
        .photo{
            margin-right: 5px;
            img{
                width: 34px;
                height: 34px;
                border-radius: 100%;
            }
        }
        .user-info{
            color: #fff;
        }
        }
        ul.nav{
            flex-direction: column;
            li{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 0px 5px 10px;
                color: #fff;
                margin-bottom: 7px;
                font-size: 12px;
                cursor: pointer;
                &:last-child{
                    border: none;
                }
                &.nav-item{
                    &.active{
                        background-color: #fff;
                        border-radius: 4px;
                        box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(60 72 88 / 40%);
                        color: #3c4858;
                    }
                }
                p{
                    margin-bottom: 0px;
                    padding-left: 10px;
                }
            }
        }
    }
}


.mini-sidebar{
    .left-side{
        width: 72px!important;
        .sidebar{
            display: block;
            width: 72px;
            .logo{
                .img-logo{
                    display: none;
                }
                .small-logo{
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }
            .sidebar-wrapper{
                ul{
                    &.nav{
                        li{
                            &.active{
                                padding: 5px 10px 5px 10px;
                            }
                            p{
                                display: none;
                            }
                        }
                    }
                }
            }
            @media (max-width: 500px) {
                display: none !important;
              }
        }
        @media (max-width: 500px) {
            width: 0px!important;
          }
    }
    .right-side{
        width: calc(100% - 60px) !important;
        @media (max-width: 500px) {
            width: calc(100% - 0px) !important;
          }
    }
}


    .left-side{
        @media (max-width: 500px) {
        width: 60px!important;
        .sidebar{
            display: block;
            width: 60px;
            .logo{
                .img-logo{
                    display: none;
                }
                .small-logo{
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }
            .sidebar-wrapper{
                ul{
                    &.nav{
                        li{
                            &.active{
                                padding: 5px 10px 5px 10px;
                            }
                            p{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    }
    .right-side{
        
        @media (max-width: 500px) {
            width: calc(100% - 60px) !important;
          }
    }
