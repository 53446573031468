.pageHeading
{
    font-family: var(--bs-font-sans-serif) !important;  
    font-weight: 500 !important; 
    text-align: center!important;
    color:#1976d2!important;
}

.fontH6
{
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 16px !important;
    @media (max-width: 500px) {
        font-weight: 200!important;
        font-size: 13px!important;
      }
}
.fontLabel
{
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 16px !important;
    @media (max-width: 500px) {
        font-weight: 200!important;
        font-size: 13px!important;
      }
}
.btnStyling
{
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 12px !important;
    text-transform: none !important;
    margin-top: 0.5% !important;
    
    @media (max-width: 500px) {
        font-weight: 200!important;
        font-size: 12px !important;
        margin-left: 2px!important;
        margin-top: 0px!important;
        margin-bottom: 1px!important;
      }
}

.mr5
{
    margin-right: 5% !important;
}

.ml1 
{
    margin-left: 1% !important;
}
.mt1 
{
    margin-top: 1% !important;
}
.mt2
{
    margin-top: 2% !important;
}
.MuiDataGrid-root
{
    color: #222!important;
    font-family: var(--bs-font-sans-serif)!important;
    font-weight: 500!important;
    font-size: 14px!important;
}
.MuiDataGrid-columnHeaders
{
    color: #fffffc!important;
    background-color: #1976d2!important;
}

.body-container {
    display: flow-root;
    padding: 0px;
    background-image: linear-gradient(#ffffff, rgba(247, 247, 247, 0.631372549));
    margin-bottom: 4px;
    border-bottom: 1px solid #ccc;
    margin-left: -10px;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 4px;
    margin-top: 10px;
    min-height: 300px;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9
{
    position: relative!important;
    min-height: 1px!important;
    padding-right: 15px!important;
    margin-top: 10px !important;
}


@media (min-width: 992px)
{
.col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9 {
    float: left;
}
}


@media (min-width: 992px)
{
.col-1 {
    width: 8.33333333%!important;
}
.col-2 {
    width: 16.66666667%!important;
}
.col-3 {
    width: 25%!important;
}
.col-4 {
    width: 33.33333333%!important;
}
.col-5 {
    width: 41.66666667%!important;
}
.col-6 {
    width: 50%!important;
}
.col-7 {
    width: 58.33333333%!important;
}
.col-8 {
    width: 66.66666667%!important;
}
.col-9 {
    width: 75%!important;
}
.col-10 {
    width: 83.33333333%!important;
}
.col-11 {
    width: 91.66666667%!important;
}
.col-12 {
    width: 100%!important;
}
}
.text-center
{
    text-align: center;
}
.MuiInputBase-input
{
    color: #222 !important;
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 14px !important;
    //height: 34px !important;
    //padding: 0.5px 14px !important;
}
.MuiAutocomplete-input
{
    //height: 18px !important;
    padding: 2.5px 4px 2.5px 6px!important;
}
.dateInput > .MuiInputBase-root > .MuiInputBase-input
{
    height: 2px !important;
    padding: 16.5px 14px !important;
    padding-right: 0!important;
}
.MuiInputLabel-animated
{
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 14px !important;
}

.textArea
{
    color: #222 !important;
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 14px !important;
}
.MuiAutocomplete-listbox > li
{
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 14px !important;
    background-color: #fafafa!important;
}
.Toastify__toast-body
{
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 14px !important;
}
.MuiDataGrid-toolbarContainer > .MuiButton-text
{
    text-transform: none !important;
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 12px !important;
}
.MuiTablePagination-selectLabel
{
    font-family: var(--bs-font-sans-serif) !important;
}
.MuiTablePagination-root >  .MuiToolbar-root > .MuiInputBase-root > .MuiTablePagination-select
{
    height: 1.4375em!important;
    padding-right: 24px!important;
    padding-left: 8px!important;
}
.MuiTablePagination-root > .MuiToolbar-gutters > .MuiTablePagination-selectLabel
{
    margin-top: revert!important; 
    margin-bottom: revert!important;
}
.MuiTablePagination-root > .MuiToolbar-gutters > .MuiTablePagination-displayedRows
{
    margin-top: revert!important; 
    margin-bottom: revert!important;
}
.MuiDataGrid-row.Mui-selected {
    background-color: #97c0e7!important;
}
.MuiDataGrid-cell:focus {
    outline: #97c0e7!important;
}
.MuiDataGrid-pinnedColumnHeaders
{
    background-color: #1976d2!important;
}
.textField > .MuiInputLabel-root
{
    line-height: 1.4375em !important;
    top: -10px !important;
}
.textField > .MuiInputLabel-shrink 
{
    line-height: 1.4375em !important;
    top: 0px !important;
}
.lockUser{
    font-family: var(--bs-font-sans-serif)!important;
    font-weight: 500!important;
    font-size: 14px!important;
    color:red;
}
.note
{   
    font-family: var(--bs-font-sans-serif) !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color:red!important;
    @media (max-width: 500px) {
        font-weight: 200!important;
        font-size: 10px!important;
      }
}
.displaynone
{
    display: none;
}
.checkbox > .MuiFormControlLabel-label
{
    color: #222 !important;
    font-family: var(--bs-font-sans-serif) !important;
    font-size: 14px !important;   
}
.pdTop5
{
    padding-top:5%;
}
.pdButtom5
{
    padding-bottom:5%;
}
                  /* Allows the MUI grid rows to expand to child height */
                  .MuiDataGrid-viewport,
                  .MuiDataGrid-row,
                  .MuiDataGrid-renderingZone {
                    max-height: fit-content !important;
                  }
                  
                  /* Allows the MUI grid rows to expand to child height */
                  .MuiDataGrid-cell {
                    max-height: fit-content !important;
                    overflow: auto;
                    white-space: initial !important;
                    line-height: 16px !important;
                    display: flex !important;
                    align-items: center;
                    //padding-top: 2px !important;
                    //padding-bottom: 2px !important;
                  }