.top-container {
    display: flex;
    padding: 0px;
    background-image: linear-gradient(#ffffff, #f7f7f7a1);
    margin-bottom: 4px;
    border-bottom: 1px solid #ccc;
    margin-left: -10px;
    padding-left: 5px;

    .navbar-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .navbar-minimize {
            box-shadow: 0 2px 2px 0 hsl(0deg 0% 60% / 14%), 0 3px 1px -2px hsl(0deg 0% 60% / 20%), 0 1px 5px 0 hsl(0deg 0% 60% / 12%);
            background-color: #fff;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: #999;
            cursor: pointer;
        }

        .page-title {
            font-size: 14px;
            color: #222;
            font-weight: 500
        }
    }

    .navbar-collapse {
        .user-right {
            text-align: end;

            .user-img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                margin-right: 10px;
            }
        }

        .user-drop {
            z-index: 9;

            ul {
                width: 110px;

                li {
                    font-size: 12px;
                    padding: 2px 10px;
                }
            }
        }
    }
}